import ServiceTypeCheck from "../../services/ServiceTypeCheck";

export interface BackendProductImages {
    [key: string]: string[];
}

export const isBackendProductImages = (content: unknown): content is BackendProductImages => {
    return (
        ServiceTypeCheck.isObject(content) &&
        ServiceTypeCheck.has(content, 'bag') && ServiceTypeCheck.isArrayOf<string>(content.bag, ServiceTypeCheck.isString) &&
        ServiceTypeCheck.has(content, 'base') && ServiceTypeCheck.isArrayOf<string>(content.base, ServiceTypeCheck.isString) &&
        ServiceTypeCheck.has(content, 'belt') && ServiceTypeCheck.isArrayOf<string>(content.belt, ServiceTypeCheck.isString)
    );
};