import ServiceTypeCheck from "../../services/ServiceTypeCheck";

export interface BackendProductIndex {
    id: string;
}

export const isBackendProductIndex = (content: unknown): content is BackendProductIndex => {
    return (
        ServiceTypeCheck.isObject(content) &&
        ServiceTypeCheck.has(content, 'id') && ServiceTypeCheck.isString(content.id)
    );
};
