export const machineAnswerKeys = ['1', '2', '3', '4'];

export const machineScreenSaverTimeout = 40000;

// Welcome Reels
// write speed
export const machineMinSpeedTextWrite = 50;
export const machineMaxSpeedTextWrite = 150;
// counter settings
export const machineMinCounterNumber = 999;
export const machineMaxCounterNumber = 9854;
export const machineSpeedCounter = 9;
export const machineAdditionsQuantities = 11;

//Text content
//footer text
export const machineFooterWeeklyWinnerText = "Поздравляем ваш приз";
export const machineFooterMonthWinnerText = "Поздравляем ваш приз";
export const machineFooterDefaultCongratulationText = "Поздравляем! Судьба выбрала для вас сумку!";
export const machineFooterDefaultDiscountText = "Приобрести именно этот комплект Вы можете со скидкой 10% только сегодня!";