import {useEffect, useState} from "react";
import "./App.scss";
import Screensaver from './components/Screensaver/Screensaver';
import SlotMachine from "./components/SlotMachine/SlotMachine";
import {machineScreenSaverTimeout} from "./constants/slotMachineSettings";

const iconsDataStart: {[key: string]: string[]} = {
    startBase: ["startBase1", "startBase2", "startBase3"],
    startBag: ["startBag1", "startBag2", "startBag3"],
    startBelt: ["startBelt1", "startBelt2", "startBelt3"],
};

let timerScreensaver: NodeJS.Timeout;

function App() {
    const [isActiveScreensaver, setActiveScreensaver] = useState(true);
    const [isUserActive, setUserActive] = useState(false);
    const [isKeyDown, setIsKeyDown] = useState(false);
    const [pressedKey, setPressedKey] = useState("");
    const [isUseMouseOrKeyboard, setIsUseMouseOrKeyboard] = useState(false);

    useEffect(() => {
        const detectedKeyDown = (event: KeyboardEvent) => {
            setPressedKey(event.key);
            setUserActive(true);

            setIsKeyDown(true);
        };

        document.removeEventListener("keydown", detectedKeyDown, true);

        document.addEventListener("keydown", detectedKeyDown, true);

        return () => {
            document.removeEventListener("keydown", detectedKeyDown, true);
        };
    }, [isActiveScreensaver]);


    useEffect(() => {
        if (isUseMouseOrKeyboard) {
            setUserActive(false);
            setActiveScreensaver(false);
            setIsUseMouseOrKeyboard(false);

            clearTimeout(timerScreensaver);
            
            timerScreensaver = setTimeout(() => {
                setUserActive(false);
                setActiveScreensaver(true);
            }, machineScreenSaverTimeout);
        }
    }, [isUseMouseOrKeyboard, isUserActive]);


    const handleKeyDown = (isKeyDown: boolean) => {
        setIsKeyDown(isKeyDown);
        setIsUseMouseOrKeyboard(true);
    };

    const handleUserActive = () => {
        // setUserActive(true);
        // setIsUseMouseOrKeyboard(true);
    };

    return (
        <>
            {
                isActiveScreensaver ? (
                    <Screensaver
                        isKeyDown={isKeyDown}
                        onKeyDown={handleKeyDown}
                        onUserActive={handleUserActive} />

                ) : (
                    <SlotMachine
                        iconsDataStart={iconsDataStart}
                        isKeyDown={isKeyDown}
                        pressedKey={pressedKey}
                        onKeyDown={handleKeyDown}
                        onUserActive={handleUserActive}
                    />
                )
            }
        </>
    );
}

export default App;
