import React, {useEffect, useRef, useState} from "react";
import {machineFooterDefaultCongratulationText, machineFooterDefaultDiscountText} from '../../../constants/slotMachineSettings';
import {SpinReelsWinnerImageShape} from '../../../shapes/SpinReelsWinnerImageShape';
import useSound from 'use-sound';
import {getRandomNum} from "../../../utils/RandomNumber";
import GeneralIcons from './Icons/GeneralIcons/GeneralIcons';
import WinIcons from './Icons/WinIcons/WinIcons';
import {spin} from './Spin/Spin';
import {SpinReelsProps} from "./SpinReelsShape";
import "./SpinReelsStyles.scss";
import rollSound from '../../../assets/sound/roll.mp3';
import winSound from '../../../assets/sound/win.mp3';
import bigWinSound from '../../../assets/sound/big-win.mp3';

const COLUMNS = 3;
const ICONS_IN_COLUMN = 60;

const SpinReels: React.FC<SpinReelsProps> = (props) => {
    const {iconsData, iconsDataStart, isKeyDown, onKeyDown} = props;

    const [rollPlay] = useSound(rollSound, {interrupt: true});
    const [winPlay, {stop: winStop}] = useSound(winSound, {interrupt: true});
    const [bigWinPlay, {stop: bigWinStop}] = useSound(bigWinSound, {interrupt: true});

    const [isSpinning, setIsSpinning] = useState(false);
    const [isDisabled, setIsDisabled] = useState(false);
    const [isSpinFinish, setIsSpinFinish] = useState(false);
    const refSpinReels = useRef(null);
    const [isWin, setIsWin] = useState(false);

    const [footerProductTitle, setFooterProductTitle] = useState("");

    const [iconWin, setIconWin] = useState<SpinReelsWinnerImageShape>(
        {fileName: "", image: "", title: ""}
    );

    const handleSpin = () => {
        if (!isKeyDown && refSpinReels.current) {
            setIsWin(false);
            spin(
                isDisabled,
                iconsData,
                refSpinReels.current,
                rollPlay,
                winPlay,
                winStop,
                bigWinPlay,
                bigWinStop,
                setIsSpinFinish,
                setIsSpinning,
                setIsDisabled,
                setIconWin
            );
        }
    };

    useEffect(() => {
        const columns = document.querySelectorAll(".col");

        for (let indColumn = 0; indColumn < columns.length; indColumn++) {
            if (!columns.hasOwnProperty(indColumn)) {
                continue;
            }

            const column = columns[indColumn];

            const bagDataValues = Object.values(iconsData);
            const bagStartDataValues = Object.values(iconsDataStart);

            column.innerHTML = getSvgIcons(
                bagDataValues[indColumn].titles,
                bagStartDataValues[indColumn]
            );
        }
    }, [iconsData, iconsDataStart]);

    useEffect(() => {
        if (isKeyDown && refSpinReels.current) {
            setIsWin(false);
            spin(
                isDisabled,
                iconsData,
                refSpinReels.current,
                rollPlay,
                winPlay,
                winStop,
                bigWinPlay,
                bigWinStop,
                setIsSpinFinish,
                setIsSpinning,
                setIsDisabled,
                setIconWin,
                onKeyDown
            );
        }
    }, [isDisabled, iconsData, isKeyDown, onKeyDown, iconsDataStart, rollPlay, winPlay, winStop, bigWinPlay, bigWinStop]);

    useEffect(() => {
        if (iconWin.title) {
            setFooterProductTitle(iconWin.title);
            setIsWin(true);
        } else {
            setFooterProductTitle(machineFooterDefaultDiscountText)
        }
    }, [iconWin.title])



    return (
        <div className="reels-spin-body">
            <GeneralIcons iconsData={iconsData} iconsDataStart={iconsDataStart} />
            <WinIcons icon={iconWin} />

            <div
                className="reels-spin-body__run"
                onClick={handleSpin}
                data-is-disabled={isDisabled}
            />

            <div
                className="reels-spin-body__wrap"
                data-is-spinning={isSpinning}
                ref={refSpinReels}
            >
                <div className="reels-spin-body__header" data-is-spin-finish={isSpinFinish}>
                    <img
                        className="reels-spin-body__header-banner-win-back"
                        src="/assets/images/style/header-banner-win-back.png"
                        alt="askstudio casino header win back"
                        data-is-spin-finish={isSpinFinish}
                    />
                    <img
                        className="reels-spin-body__header-banner-win-title"
                        src="/assets/images/style/header-banner-win-title.png"
                        alt="askstudio casino header win title"
                        data-is-spin-finish={isSpinFinish}
                    />
                    <img
                        className="reels-spin-body__header-banner-default"
                        src="/assets/images/style/header-banner-default.png"
                        alt="askstudio casino header default"
                        data-is-spin-finish={isSpinFinish}
                    />
                </div>

                <div className="reels-spin-body__win-frame" data-is-spin-finish={isSpinFinish}>
                    <img
                        className="reels-spin-body__win-frame-image"
                        src="/assets/images/style/win-frame.png"
                        alt="win frame"
                    />
                </div>

                <div className="reels-spin-body__window-wrap">
                    <div className="reels-spin-body__window">
                        {getColumn(COLUMNS)}
                    </div>
                </div>

                <div
                    className="reels-spin-body__footer"
                    data-is-spin-finish={isSpinFinish}
                >
                    <img
                        className="reels-spin-body__footer-banner-back"
                        src="/assets/images/style/footer-banner-win-back.png"
                        alt="askstudio casino footer win back"
                        data-is-spin-finish={isSpinFinish}
                    />

                    {
                        isWin &&
                        <div className="reels-spin-body__footer-banner-win-title" data-is-spin-finish={isSpinFinish}>
                            <img
                                className="reels-spin-body__footer-banner-win-image"
                                src="/assets/images/style/footer-banner-win-title.png"
                                alt="askstudio casino footer win title"
                                data-is-spin-finish={isWin}
                            />
                            <p
                                className="reels-spin-body__footer-banner-win-text"
                                data-is-spin-finish={isWin}>
                                {footerProductTitle}
                            </p>
                        </div>
                    }

                    {
                        !isWin &&
                        <div className="reels-spin-body__footer-banner-default" data-is-spin-finish={isSpinFinish}>
                            <div className="reels-spin-body__footer-banner-default-title">
                                <img
                                    className="reels-spin-body__footer-banner-default-star-one"
                                    src="/assets/images/style/footer-banner-default-start.png"
                                    alt="askstudio casino footer win title"
                                    data-is-spin-finish={isSpinFinish}
                                />
                                <p
                                    className="reels-spin-body__footer-banner-default-congratulation-text"
                                    data-is-spin-finish={isSpinFinish}>
                                    {machineFooterDefaultCongratulationText}
                                </p>
                                <img
                                    className="reels-spin-body__footer-banner-default-star-two"
                                    src="/assets/images/style/footer-banner-default-start.png"
                                    alt="askstudio casino footer win title"
                                    data-is-spin-finish={isSpinFinish}
                                />
                            </div>
                            <p
                                className="reels-spin-body__footer-banner-default-discount-text"
                                data-is-spin-finish={isSpinFinish}>
                                {machineFooterDefaultDiscountText}
                            </p>
                        </div>
                    }
                </div>
            </div>
        </div>
    );
};


export default SpinReels;


const getSvgIcons = (icons: string[], previewIcons: string[]) => {
    let str = "";
    let firstThree = "";

    for (let index = 0; index < ICONS_IN_COLUMN; index++) {
        const randomNum = Math.floor(getRandomNum(icons.length));
        let part = "";

        if (index < 3) {
            part = `<svg class="icon"><use xlink:href="#icon-${previewIcons[index]}"></use></svg>`;
            firstThree += part;
        } else {
            part = `<svg class="icon"><use xlink:href="#icon-${icons[randomNum]}"></use></svg>`;
        }

        str += part;
    }

    const result = str + firstThree;
    return result;
};

const getColumn = (colNumber: number) => {
    const column = [];
    for (let index = 0; index < colNumber; index++) {
        column.push(
            <div className="outer-col" key={`outer-col=${index}`}>
                <div className="col" />
            </div>
        );
    }

    return column;
};