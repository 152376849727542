import React, {useEffect} from "react";
import {ScreensaverProps} from "./ScreensaverShape";
import "./ScreensaverStyle.scss";

const Screensaver: React.FC<ScreensaverProps> = (props) => {
    const {isKeyDown, onKeyDown, onUserActive} = props;

    useEffect(() => {
        if (isKeyDown) {
            onKeyDown(false);
        }
    }, [isKeyDown, onKeyDown]);


    const handleMouseMove = () => {
        onUserActive(true);
    };

    const handleMouseClick = () => {
        onUserActive(true);
    };

    return (
        <>
            <iframe
                className="ask-screensaver"
                title="ask-screensaver"
                src="/assets/ask-screensaver.html"
            />
            <div
                className="ask-screensaver-wrap"
                onClick={handleMouseClick}
                onMouseMove={handleMouseMove}
            />
        </>
    );
};


export default Screensaver;
