export enum AnswerModel {
    leather = 'КОЖАНУЮ',
    plastic = 'ПЛАСТИКОВУЮ',
    plastic_and_leather = 'МИКС',
    random = 'ПУСТЬ РЕШИТ СУДЬБА',
}

export enum AnswerSize {
    maxi = 'МАКСИ',
    medium = 'МЕДИУМ',
    mini = 'МИНИ',
    random = 'ПУСТЬ РЕШИТ СУДЬБА',
}
