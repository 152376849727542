import axios, {AxiosError, AxiosRequestConfig, AxiosResponse, Method} from 'axios';

interface ApiHeaders {
    [key: string]: any
}

class ServiceApi {

    private request = <T>(method: Method, url: string, config?: AxiosRequestConfig, data?: any): Promise<T> => {
        const headers: ApiHeaders = {};

        return axios({method, url, data, headers, ...config})
            .then((response: AxiosResponse) => response.data)
            .catch((response: AxiosError) => this.errorHandler(response))
            .catch((response: AxiosError) => this.errorUnuathorized(response))
    };

    protected get = <T>(url: string, config?: AxiosRequestConfig): Promise<T> => {
        return this.request('get', url, config);
    };

    protected post = <T>(url: string, data?: any, config?: AxiosRequestConfig): Promise<T> => {
        return this.request('post', url, config, data);
    };

    protected put = <T>(url: string, data?: any, config?: AxiosRequestConfig): Promise<T> => {
        return this.request('put', url, config, data);
    };

    protected delete = <T>(url: string, config?: AxiosRequestConfig): Promise<T> => {
        return this.request('delete', url, config);
    };

    private errorHandler = (error: AxiosError): void => {
        console.error(`Request Catch. Message: ${error.message}`);
        throw error;
    };

    private errorUnuathorized = (error: AxiosError): void => {
        throw error.response;
    }
}

export default ServiceApi;
