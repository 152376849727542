import {TypeChecker} from "../shapes/TypeChecker";

class ServiceTypeCheck {

    private error = (isPassed: boolean, content: unknown, message: string = 'this value has failed') => {
        if (!isPassed && process.env.NODE_ENV === 'development') {
            console.error(`Typecheck: ${message} :`, content);
        }
    }

    has = <X extends {}, Y extends PropertyKey>(obj: X, prop: Y): obj is X & Record<Y, unknown> => {
        const isPassed = obj.hasOwnProperty(prop)
        this.error(isPassed, obj, `Object does not contain ${prop.toString()}`);
        return  isPassed;
    };

    isObject = (content: unknown): content is object => {
        const isPassed = typeof content === 'object' && content != null;
        this.error(isPassed, content, 'not an object');
        return isPassed;
    };

    isNull = (content: unknown): content is null => {
        const isPassed = typeof content === 'object' && content === null;
        this.error(isPassed, content, 'not a null value');
        return isPassed;
    };

    isBoolean = (content: unknown): content is boolean => {
        const isPassed = typeof content === 'boolean';
        this.error(isPassed, content, 'not a boolean');
        return isPassed;
    };

    isNumber = (content: unknown): content is number => {
        const isPassed = typeof content === 'number' && !isNaN(content) && isFinite(content);
        this.error(isPassed, content, 'not a number value');
        return isPassed;
    };

    isString = (content: unknown): content is string => {
        const isPassed = typeof content === 'string';
        this.error(isPassed, content, 'not a string');
        return isPassed;
    };

    isStringOrNumber =  (content: unknown): content is string | number => {
        const isNumber = typeof content === 'number' && !isNaN(content) && isFinite(content);
        const isString = typeof content === 'string';

        this.error(isString || isNumber, content, 'not a string or number');

        return isString || isNumber;
    }

    isFunction = (content: unknown): content is Function => {
        const isPassed = typeof content === 'function';
        this.error(isPassed, content, 'not a function');
        return isPassed;
    }

    isArray = (content: unknown): content is Array<unknown> => {
        const isPassed = Array.isArray(content);
        this.error(isPassed, content, 'not an array');
        return isPassed;
    }

    isArrayOf = <T>(content: unknown, checker: TypeChecker<T>): content is T[] => {
        const isPassed = content != null && this.isArray(content) && content.every((el) => checker(el));
        this.error(isPassed, content, 'Is not an array of type');
        return isPassed;
    }

    // isEnumValue = <T>(content: unknown, source: T): content is T[keyof T] => {
    //     const isPassed = Object.values(source).includes(content as T[keyof T]);
    //     this.error(isPassed, content, 'Is not an Enum type');
    //     return isPassed;
    // }


    // checkSingleTypes = <T>(data: unknown, checker: TypeChecker<T>, error: string = 'RESPONSE_ERROR'): boolean | never => {
    //     if (data != null && checker(data)) {
    //         return true;
    //     } else {
    //         throw new Error(error);
    //     }
    // }

}

export default new ServiceTypeCheck();












