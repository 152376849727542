import ServiceTypeCheck from "../../services/ServiceTypeCheck";

export interface BackendWinningImages {
    imageMonthWinner: string | null;
    imageWeeklyWinner: string | null;
    titleWinner: string | null;
}

export const isBackendWinningImages = (content: unknown): content is BackendWinningImages => {
    return (
        ServiceTypeCheck.isObject(content)
        && ServiceTypeCheck.has(content, 'imageMonthWinner') && (ServiceTypeCheck.isNull(content.imageMonthWinner) || ServiceTypeCheck.isString(content.imageMonthWinner))
        && ServiceTypeCheck.has(content, 'imageWeeklyWinner') && (ServiceTypeCheck.isNull(content.imageWeeklyWinner) || ServiceTypeCheck.isString(content.imageWeeklyWinner))
        && ServiceTypeCheck.has(content, 'titleWinner') && (ServiceTypeCheck.isNull(content.titleWinner) || ServiceTypeCheck.isString(content.titleWinner))
    );
};