import {BackendProductDataDetail} from "./BackendProductDataDetail";
import ServiceTypeCheck from "../../services/ServiceTypeCheck";

export interface BackendProductDataBlock {
    id: string;
    items: BackendProductDataDetail[];
}

export const isBackendProductDataBlock = (content: unknown): content is BackendProductDataBlock => {
    return (
        ServiceTypeCheck.isObject(content) &&
        ServiceTypeCheck.has(content, 'id') && ServiceTypeCheck.isString(content.id) &&
        ServiceTypeCheck.has(content, 'items') && ServiceTypeCheck.isArray(content.items)
    );
};
