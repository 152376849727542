import {SpinReelsWinnerImageShape} from '../../../../../shapes/SpinReelsWinnerImageShape';
import {SpinReelsWinIconsProps} from './WinIconsShapes'


const WinIcons: React.FC<SpinReelsWinIconsProps> = (props) => {
    const {icon} = props;
    const svgIcons = createSvgWin(icon);

    return (
        <>
            {svgIcons}
        </>
    )
}

export default WinIcons;


const createSvgWin = (icon: SpinReelsWinnerImageShape) => {
    const localIcons = icon;

    return (
        <div className="reels-spin-body__svg" key={`svg-${icon}`}>
            <svg
                style={{
                    position: "absolute",
                    width: 0,
                    height: 0,
                    overflow: "hidden",
                }}
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
            >
                <defs key={`svg-${localIcons.fileName}`}>
                    <symbol id={`icon-${localIcons.fileName}`} viewBox="0 0 32 32">
                        <image
                            href={`${localIcons.image}`}
                            width="100%"
                            height="100%"
                        />
                    </symbol>
                </defs>
            </svg>
        </div>
    );
};