export const counter = (speedCounter: number, maxCount: number, refCounter: React.RefObject<HTMLElement>, quantities: number = 1) => {
    let currentCounter: NodeJS.Timer;

    const count = () => {
        const currentElement = refCounter.current;

        if (currentElement && currentElement.innerText) {

            const num = parseInt(currentElement.innerText);

            if (num < maxCount) {
                currentElement.innerHTML = (num + quantities).toString();
            } else {
                onFinish();
            }
        } else {
            onFinish();
        }
    }

    const onFinish = () => {
        clearInterval(currentCounter);
    }


    currentCounter = setInterval(count, speedCounter)
}

