import React, {useEffect, useState} from "react";
import "./SlotMachineStyles.scss";
import {SlotMachineProps, SlotMachineShape} from "./SlotMachineShape";
import WelcomeReels from "./WelcomeReels/WelcomeReels";
import QuestionReels from "./QuestionReels/QuestionReels";
import SpinReels from "./SpinReels/SpinReels";
import ServiceRequests from "../../services/ServiceRequests";
import {getRandomArbitrary} from "../../utils/RandomNumber";
import {Question} from "../../shapes/Question";
import {AnswerModel, AnswerSize} from "../../enums/Answer";
import {QuestionTitle} from "../../enums/QuestionTitle";
import {machineAnswerKeys} from "../../constants/slotMachineSettings";
import {answerKeysCheck} from "../../utils/KeysCheck";

//TODO: плавный переход между страницами
const SlotMachine: React.FC<SlotMachineProps> = (props) => {
    const {
        iconsDataStart,
        isKeyDown,
        pressedKey,
        onUserActive,
        onKeyDown,
    } = props;

    const [questions, setQuestions] = useState<Question[]>([]);
    const [isQuestionsModelsLoad, setIsQuestionsModelsLoad] = useState(false);
    const [isQuestionsSizesLoad, setIsQuestionsSizesLoad] = useState(false);

    const [modelsIds, setModelsIds] = useState<SlotMachineShape["modelsId"]>([]);
    const [activeModelId, setActiveModelId] = useState<SlotMachineShape["activeModelId"]>(null);
    const [isModelIdLoaded, setIsModelIdLoaded] = useState(false);
    const [inProcessLoadModel, setInProcessLoadModel] = useState(false);

    const [sizesIds, setSizesIds] = useState<SlotMachineShape["sizesId"]>([]);
    const [activeSizeId, setActiveSizeId] = useState<SlotMachineShape["activeSizeId"]>(null);
    const [isSizeIdLoaded, setIsSizeIdLoaded] = useState(false);
    const [inProcessLoadSize, setInProcessLoadSize] = useState(false);

    const [randomImages, setRandomImages] = useState<SlotMachineShape["randomImages"]>();
    const [randomImagesKeys, setRandomImagesKeys] = useState<string[]>([]);

    const [steps, setSteps] = useState(0);
    const [isImagesLoaded, setIsImagesLoaded] = useState(false);
    const [inProcessLoadImages, setInProcessLoadImages] = useState(false);

    useEffect(() => {
        if (isKeyDown) {
            const nextStep = steps + 1;

            switch (steps) {
                case 0:
                    if (modelsIds.length) {
                        setSteps(nextStep);
                        onKeyDown(false);
                    }
                    break;
                case 1:
                    if (!sizesIds.length && !isSizeIdLoaded) {
                        choiceAnswer(modelsIds, pressedKey, setActiveModelId);
                    } else if (sizesIds.length && isSizeIdLoaded) {
                        toggleNextStep(nextStep, 1000, setSteps, onKeyDown);
                    }
                    break;
                case 2:
                    if (!randomImagesKeys.length && !isImagesLoaded) {
                        choiceAnswer(sizesIds, pressedKey, setActiveSizeId);
                    } else if (randomImagesKeys.length && isImagesLoaded) {
                        toggleNextStep(nextStep, 1000, setSteps, onKeyDown);
                    }
                    break;
                default:
                    break;
            }
        }
    }, [
        isImagesLoaded,
        isKeyDown,
        isSizeIdLoaded,
        modelsIds,
        onKeyDown,
        pressedKey,
        randomImagesKeys.length,
        sizesIds,
        sizesIds.length,
        steps
    ]);

    useEffect(() => {
        (async () => {
            try {
                if (!isModelIdLoaded && !inProcessLoadModel) {
                    setInProcessLoadModel(true);
                    const responseModelsIds = await ServiceRequests.getModelsId();

                    if (responseModelsIds && responseModelsIds.length > 0) {
                        setModelsIds(responseModelsIds);
                    } else {
                        console.error("Product Models are not loaded!");
                    }
                }
            } catch (error) {
                console.error("Error getting Model");
            } finally {
                setIsModelIdLoaded(true);
                setInProcessLoadModel(false);
            }
        })();
    }, [inProcessLoadModel, isModelIdLoaded]);

    useEffect(() => {
        if (!activeModelId) {
            return;
        }

        (async () => {
            try {
                if (!isSizeIdLoaded && !inProcessLoadSize) {
                    setInProcessLoadSize(true);
                    const responseSizesIds = await ServiceRequests.getSizesId(
                        activeModelId
                    );

                    if (responseSizesIds && responseSizesIds.length > 0) {
                        setSizesIds(responseSizesIds);
                    } else {
                        console.error("Product Size are not loaded!");
                    }
                }
            } catch (err: any) {
                console.error("Error getting Size");
            } finally {
                setIsSizeIdLoaded(true);
                setInProcessLoadSize(false);
            }
        })();
    }, [activeModelId, inProcessLoadSize, isSizeIdLoaded]);

    useEffect(() => {
        if (!activeModelId || !activeSizeId) {
            return;
        }

        (async () => {
            try {
                if (!isImagesLoaded && !inProcessLoadImages) {

                    setInProcessLoadImages(true);
                    const responseImages = await ServiceRequests.getImages(
                        activeModelId,
                        activeSizeId
                    );

                    if (responseImages) {
                        setRandomImages(responseImages);
                        setRandomImagesKeys(Object.keys(responseImages));
                    } else {
                        console.error("Product Images are not loaded!");
                    }
                }
            } catch (err: any) {
                console.error("Error getting Images");
            } finally {
                setIsImagesLoaded(true);
                setInProcessLoadImages(false);
            }
        })();
    }, [activeModelId, activeSizeId, inProcessLoadImages, isImagesLoaded]);

    useEffect(() => {
        addQuestion(
            modelsIds,
            isQuestionsModelsLoad,
            questions,
            QuestionTitle.model,
            AnswerModel,
            setQuestions,
            setIsQuestionsModelsLoad);
    }, [isQuestionsModelsLoad, modelsIds, questions]);

    useEffect(() => {
        addQuestion(
            sizesIds,
            isQuestionsSizesLoad,
            questions,
            QuestionTitle.size,
            AnswerSize,
            setQuestions,
            setIsQuestionsSizesLoad);
    }, [isQuestionsSizesLoad, questions, sizesIds]);

    const handleMouseMove = () => {
        onUserActive();
    };

    const handleMouseClick = () => {
        onUserActive();
    };

    //TODO: create enum with id, rename steps to enum id
    return (
        <div
            className="machine__wrapper"
            onMouseMove={handleMouseMove}
            onClick={handleMouseClick}
        >
            {steps === 0 && <WelcomeReels />}

            {steps === 1 && questions[0] && (
                <QuestionReels
                    question={questions[0]}
                    isKeyDown={isKeyDown}
                    pressedKey={pressedKey}
                />
            )}

            {steps === 2 && questions[1] && (
                <QuestionReels
                    question={questions[1]}
                    isKeyDown={isKeyDown}
                    pressedKey={pressedKey}
                />
            )}

            {steps === 3 && randomImages && (
                <SpinReels
                    iconsData={randomImages}
                    iconsDataStart={iconsDataStart}
                    isKeyDown={isKeyDown}
                    onKeyDown={onKeyDown}
                />
            )}

            <div className="machine__nail" data-position="left-top" />
            <div className="machine__nail" data-position="left-bottom" />
            <div className="machine__nail" data-position="right-top" />
            <div className="machine__nail" data-position="right-bottom" />
        </div>
    );
};


export default SlotMachine;


const addQuestion = (
    contentId: string[],
    isQuestionsLoad: boolean,
    questions: Question[],
    questionTitle: string,
    answerMapping: {[key: string]: string},
    setQuestions: (questions: Question[]) => void,
    setIsQuestionsLoad: (isLoad: boolean) => void) => {

    if (contentId.length && !isQuestionsLoad) {
        const contentsIds = contentId.map((content) => content);
        contentsIds.push("random");

        setQuestions([
            ...questions,
            {
                answerOptions: contentsIds,
                title: questionTitle,
                answerMapping: answerMapping,
            },
        ]);

        setIsQuestionsLoad(true);
    }
}

const toggleNextStep = (
    nextStep: number,
    delayTime: number = 1000,
    onNextStep: (step: number) => void,
    onKeyDown: (isDown: boolean) => void,
) => {
    setTimeout(() => {
        onNextStep(nextStep);
        onKeyDown(false);
    }, delayTime);
}

const choiceAnswer = (
    items: string[],
    pressedKey: string,
    callback: (id: string) => void
) => {
    if (answerKeysCheck(pressedKey, machineAnswerKeys, items.length)) {
        const indexNumber = machineAnswerKeys.indexOf(pressedKey);

        if (items.length) {
            let modelIdNumber = 0;

            if (items[indexNumber]) {
                modelIdNumber = indexNumber;
            } else {
                const length = items.length - 1;
                modelIdNumber = getRandomArbitrary(0, length);
            }

            let active = items[modelIdNumber];

            if (active) {
                callback(active);
            }
        }
    }
}
