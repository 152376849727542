import {endpoints} from "../constants/endpoints";
import {Param} from "../shapes/Param";
import {isBackendProductDataBlock} from "../shapes/backend/BackendProductDataBlock";
import {isBackendProductImages} from "../shapes/backend/BackendProductImages";
import {isBackendProductIndex, BackendProductIndex} from "../shapes/backend/BackendProductIndex";
import ServiceApi from "./ServiceApi";
import ServiceTypeCheck from "./ServiceTypeCheck";
import {convertToSpinReelsImages, convertToSpinReelsWinnerImage} from "../utils/Converters";
import {isBackendWinningImages} from '../shapes/backend/BackendWinningImages';

class ServiceRequests extends ServiceApi {
    getModelsId = async () => {
        const response = await this.get(endpoints.models());

        if (Array.isArray(response) &&
            ServiceTypeCheck.isArrayOf<BackendProductIndex>(response, isBackendProductIndex)) {

            return response
                // this line preventing any types of bases except passed ones
                .filter(item => ['plastic', 'leather', 'plastic_and_leather'].includes(item.id))
                .map(item => item.id);

        } else {
            throw new Error("Incorrect product models");
        }
    };

    getSizesId = async (type: Param = null) => {
        const response = await this.get(endpoints.sizes(), {
            params: {type},
        });

        if (response && isBackendProductDataBlock(response)) {
            return response.items.map(item => item.id);
        } else {
            throw new Error("Incorrect product sizes");
        }
    };

    getImages = async (type: Param = null, size: Param = null) => {
        const response = await this.get(endpoints.images(), {
            params: {type, size},
        });

        if (response && isBackendProductImages(response)) {
            return convertToSpinReelsImages(response);
        } else {
            throw new Error("Incorrect product images");
        }
    };

    getWinningImages = async () => {
        const response = await this.get(endpoints.winningImages());

        if (response && isBackendWinningImages(response)) {
            return convertToSpinReelsWinnerImage(response);
        } else {
            throw new Error("Incorrect product winning images");
        }
    };
}

export default new ServiceRequests();
