import {machineFooterMonthWinnerText, machineFooterWeeklyWinnerText} from '../constants/slotMachineSettings';
import {BackendProductImages} from '../shapes/backend/BackendProductImages';
import {BackendWinningImages} from '../shapes/backend/BackendWinningImages';
import {SpinReelsImagesShape} from '../shapes/SpinReelsImagesShape';
import {SpinReelsWinnerImageShape} from '../shapes/SpinReelsWinnerImageShape';
import {getFileName, getFilesNames} from './FileName';


export const convertToSpinReelsImages = (productImages: BackendProductImages): SpinReelsImagesShape => {
    const keys = Object.keys(productImages);
    let result: {
        [key: string]: {
            titles: string[],
            images: string[],
        }
    } = {}

    for (let index = 0; index < keys.length; index++) {
        const currentKey = keys[index];
        const currentProductImages = productImages[currentKey];

        const titles = getFilesNames(currentProductImages)

        result = {
            ...result,
            [currentKey]: {
                titles: titles,
                images: currentProductImages,
            }
        }
    }

    return result;
}


export const convertToSpinReelsWinnerImage = (productImages: BackendWinningImages): SpinReelsWinnerImageShape => {
    const imageMonthWinner = productImages.imageMonthWinner;
    const imageWeeklyWinner = productImages.imageWeeklyWinner;
    const titleWinner = productImages.titleWinner;

    if (imageMonthWinner && titleWinner) {
        const winnerText = `${machineFooterMonthWinnerText} ${titleWinner}!!!`
        return getSpinReelsWinnerImage(imageMonthWinner, winnerText);
    }

    if (imageWeeklyWinner && titleWinner) {
        const winnerText = `${machineFooterWeeklyWinnerText} ${titleWinner}!!!`
        return getSpinReelsWinnerImage(imageWeeklyWinner, winnerText);
    }

    return {
        fileName: "",
        image: "",
        title: "",
    }
}

const getSpinReelsWinnerImage = (imageUrl: string, title: string): SpinReelsWinnerImageShape => {
    const fileName = getFileName(imageUrl);

    return {
        fileName,
        image: imageUrl,
        title: title,
    }
}
