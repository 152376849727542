import {PlayFunction} from 'use-sound/dist/types';
import ServiceRequests from '../../../../services/ServiceRequests';
import {SpinReelsImagesShape} from '../../../../shapes/SpinReelsImagesShape';
import {SpinReelsWinnerImageShape} from '../../../../shapes/SpinReelsWinnerImageShape';
import {getRandomNum} from '../../../../utils/RandomNumber';


const winCheckTime = 500;
const winIconsSearchTime = 3500;
const winTime = 5500;
const finishTime = 6000;


export const spin = async (
    isDisabled: boolean,
    iconsData: SpinReelsImagesShape,
    refSpinReels: HTMLElement,
    rollPlay: PlayFunction,
    winPlay: PlayFunction,
    winStop: (id?: string | undefined) => void,
    bigWinPlay: PlayFunction,
    bigWinStop: (id?: string | undefined) => void,
    setWin: React.Dispatch<React.SetStateAction<boolean>>,
    setSpinning: React.Dispatch<React.SetStateAction<boolean>>,
    setDisabled: React.Dispatch<React.SetStateAction<boolean>>,
    setIconWin: (icon: SpinReelsWinnerImageShape) => void,
    onKeyDown?: (isKeyDown: boolean) => void
) => {
    if (!isDisabled) {
        const slots = refSpinReels;
        let isBigWin = false;

        rollPlay();
        winStop();
        bigWinStop();

        if (slots) {
            setWin(false);
            setSpinning(true);
            setDisabled(true);
        }

        let iconsWinLink: SpinReelsWinnerImageShape | null = null;

        setTimeout(() => {
            loadIconWin()
                .then(response => {
                    iconsWinLink = response || null;
                    iconsWinLink && setIconWin(iconsWinLink);
                })
                .catch(err => {
                    iconsWinLink = null;
                })
        }, winCheckTime);

        setTimeout(() => {
            const columns = document.querySelectorAll(".col");

            const bagDataValues = Object.values(iconsData);

            if (columns.length === bagDataValues.length)

                if (iconsWinLink && iconsWinLink.image) {
                    isBigWin = true;

                    const columnContentWin = iconsWinLink.fileName;

                    for (let index = 0; index < columns.length; index++) {
                        if (!columns.hasOwnProperty(index)) {
                            continue;
                        }

                        const column = columns[index];
                        const iconsColumn = column.querySelectorAll(".icon use");

                        const columnContent = bagDataValues[index];

                        const results = [
                            columnContent.titles[getRandomNum(columnContent.titles.length)],
                            columnContentWin,
                            columnContent.titles[getRandomNum(columnContent.titles.length)],
                        ];

                        insertWinImages(iconsColumn, results);
                    }
                } else {
                    for (let index = 0; index < columns.length; index++) {
                        if (!columns.hasOwnProperty(index)) {
                            continue;
                        }

                        const column = columns[index];
                        const iconsColumn = column.querySelectorAll(".icon use");

                        const columnContent = bagDataValues[index];

                        const results = [
                            columnContent.titles[getRandomNum(columnContent.titles.length)],
                            columnContent.titles[getRandomNum(columnContent.titles.length)],
                            columnContent.titles[getRandomNum(columnContent.titles.length)],
                        ];

                        insertWinImages(iconsColumn, results);
                    }
                }


        }, winIconsSearchTime);

        setTimeout(() => {
            if (slots) {
                setWin(true);
                isBigWin ? bigWinPlay() : winPlay();
            }
        }, winTime);

        setTimeout(() => {
            if (slots) {
                setSpinning(false);
                setDisabled(false);


                if (onKeyDown) {
                    onKeyDown(false);
                }
            }
        }, finishTime);
    }
};

const insertWinImages = (iconsColumn: NodeListOf<Element>, results: string[]) => {
    for (let index = 0; index < 3; index++) {
        iconsColumn[index].setAttribute(
            "xlink:href",
            "#icon-" + results[index]
        );

        iconsColumn[iconsColumn.length - 3 + index].setAttribute("xlink:href", "#icon-" + results[index]);
    }
}

const loadIconWin = () => {
    return ServiceRequests.getWinningImages()
        .then(response => {
            return response;
        })
        .catch(err => {
            console.error("Error getting Model");
        });
}