const base = `${process.env.REACT_APP_DOMAIN || ''}/wp-json/juzt-ask/v1`;
//const base = 'https://staging.askstudio.ru/wp-json/juzt-ask/v1';

export const endpoints = {
    models: () => base + "/builder-models",
    sizes: () => base + "/builder-sizes",
    data: () => base + "/builder-data",
    images: () => base + "/randomizer-image",
    winningImages: () => base + "/randomizer-winner",
    productData: () => "/wp-admin/admin-ajax.php",
};
