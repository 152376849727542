const regPathToFile = new RegExp(/^.*\/(.*)$/);

export const getFileNameWithExtension = (filePath: string) => {
    return filePath.replace(regPathToFile, "$1");
}

export const getFileName = (filePath: string) => {
    const titleWithExt = getFileNameWithExtension(filePath);
    return titleWithExt.substring(0, titleWithExt.lastIndexOf('.')) || titleWithExt;
}

export const getFilesNames = (filesPaths: string[]) => {
    const titlesWithExt = filesPaths.map(filePath => getFileNameWithExtension(filePath));
    return titlesWithExt.map(title => title.substring(0, title.lastIndexOf('.')) || title);
}

