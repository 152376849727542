import {useEffect, useState} from 'react';
import {SpinReelsIconsShape} from '../../../../../shapes/SpinReelsIconsShape';
import {SpinReelsImagesShape} from '../../../../../shapes/SpinReelsImagesShape';
import {GeneralIconsProps} from './GeneralIconsShapes';


const GeneralIcons: React.FC<GeneralIconsProps> = (props) => {
    const {iconsData, iconsDataStart} = props;

    const [svgIcons, setSvgIcons] = useState<JSX.Element[]>([]);

    useEffect(() => {
        const bagDataKey = Object.keys(iconsData);
        const bagStartDataKey = Object.keys(iconsDataStart);
        const result: JSX.Element[] = [];

        for (let index = 0; index < bagDataKey.length; index++) {
            const newSvg = createSvgElement(
                iconsData,
                bagDataKey[index]
            );

            result.push(newSvg);
        }

        for (let index = 0; index < bagStartDataKey.length; index++) {
            const newSvg = createStartSvgElement(iconsDataStart, bagStartDataKey[index]);

            result.push(newSvg);
        }
        setSvgIcons(result);
    }, [iconsData, iconsDataStart]);

    return (
        <>
            {svgIcons.map((svg, index) => {
                return (
                    <div className="reels-spin-body__svg" key={`svg-${index}`}>
                        {svg}
                    </div>
                );
            })}
        </>
    )
}

export default GeneralIcons;


const createStartSvgElement = (localIcons: SpinReelsIconsShape, dataKey: string) => {
    const icons = localIcons[dataKey];

    return (
        <svg
            style={{
                position: "absolute",
                width: 0,
                height: 0,
                overflow: "hidden",
            }}
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
        >
            {icons.map((element, index) => {
                return (
                    <defs key={`svg-${index}`}>
                        <symbol id={`icon-${element}`} viewBox="0 0 32 32">
                            <image
                                href={`/assets/images/icons/${element}.png`}
                                width="100%"
                                height="100%"
                            />
                        </symbol>
                    </defs>
                );
            })}
        </svg>
    );
};

const createSvgElement = (backIcons: SpinReelsImagesShape, dataKey: string) => {
    const icons = backIcons[dataKey];

    return (
        <svg
            style={{
                position: "absolute",
                width: 0,
                height: 0,
                overflow: "hidden",
            }}
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
        >
            {icons.images.map((element, indElement) => {
                const title = icons.titles[indElement];

                return (
                    <defs key={`svg-${indElement}`}>
                        <symbol id={`icon-${title}`} viewBox="0 0 32 32">
                            <image
                                href={`${element}`}
                                width="100%"
                                height="100%"
                            />
                        </symbol>
                    </defs>
                );
            })}
        </svg>
    );
};
