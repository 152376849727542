import React, {useEffect, useMemo, useRef, useState} from "react";
import useSound from 'use-sound';
import {
    machineMaxCounterNumber,
    machineMaxSpeedTextWrite,
    machineMinCounterNumber,
    machineMinSpeedTextWrite,
    machineAdditionsQuantities,
    machineSpeedCounter,
} from "../../../constants/slotMachineSettings";
import {counter} from "../../../utils/Counter";
import {getRandomArbitrary} from "../../../utils/RandomNumber";
import {WelcomeReelsProps} from "./WelcomeReelsShape";
import "./WelcomeReelsStyles.scss";
import keyboardSound from '../../../assets/sound/keyboard.mp3';


interface ContentTypeWriter {
    text: string;
    textPosition: number;
    element: Element | null;
}


const WelcomeReels: React.FC<WelcomeReelsProps> = () => {
    const [playKeyboard, {stop: keyboardStop}] = useSound(keyboardSound, {volume: 0.25, interrupt: true});

    const [step, setStep] = useState(0);
    const [isNextStep, setIsNextStep] = useState(true);

    const [textWelcome, setTextWelcome] = useState<ContentTypeWriter[]>();

    const [isStartCounter, setIsStartCounter] = useState(false);
    const refCounter = useRef<HTMLParagraphElement>(null);

    const refTypeWriter = useRef<HTMLHeadingElement>(null);

    const typeWriter = useMemo(
        () => (arrTypeWriter: ContentTypeWriter) => () => {
            const element = arrTypeWriter.element;
            const text = arrTypeWriter.text;
            let textPosition = arrTypeWriter.textPosition;

            if (element) {
                element.innerHTML =
                    text.substring(0, textPosition) +
                    `<span class="machine-greeting-underscore">\u005f</span>`;

                const speedWrite = getRandomArbitrary(
                    machineMinSpeedTextWrite,
                    machineMaxSpeedTextWrite
                );

                if (textPosition++ !== text.length) {
                    setTimeout(
                        typeWriter({text, textPosition, element}),
                        speedWrite
                    );
                } else {
                    element.innerHTML = text.substring(0, textPosition);
                    setStep(step + 1);
                    setIsNextStep(true);
                    keyboardStop();
                }
            }
        },
        [keyboardStop, step]
    );

    useEffect(() => {
        if (
            isStartCounter &&
            refCounter.current &&
            refCounter.current.innerText
        ) {
            counter(machineSpeedCounter, machineMaxCounterNumber, refCounter, machineAdditionsQuantities);
        }
    }, [refCounter, isStartCounter, playKeyboard]);

    useEffect(() => {
        return () => {
            keyboardStop();
        }
    }, [keyboardStop]);


    useEffect(() => {
        if (refTypeWriter) {
            setTextWelcome([
                {
                    text: "Привет!",
                    textPosition: 0,
                    element: document.querySelector("#machine-greeting0"),
                },
                {
                    text: "Я помощник Рандомайзер ASK!",
                    textPosition: 0,
                    element: document.querySelector("#machine-message1"),
                },
                {
                    text: "Не можешь выбрать ту самую сумку среди ",
                    textPosition: 0,
                    element: document.querySelector("#machine-message2"),
                },
                {
                    text: machineMinCounterNumber.toString(),
                    textPosition: 0,
                    element: document.querySelector("#machine-message3"),
                },
                {
                    text: " вариантов?",
                    textPosition: 0,
                    element: document.querySelector("#machine-message4"),
                },
                {
                    text: "Я помогу тебе определиться!",
                    textPosition: 0,
                    element: document.querySelector("#machine-message5"),
                },
                {
                    text: "Жми на любую кнопку, чтобы начать!",
                    textPosition: 0,
                    element: document.querySelector("#machine-message6"),
                },
            ]);
        }
    }, [refTypeWriter]);

    useEffect(() => {
        if (refTypeWriter && isNextStep && textWelcome && textWelcome[step]) {
            playKeyboard();
            setIsNextStep(false);
            typeWriter(textWelcome[step])();
        }
    }, [textWelcome, refTypeWriter, isNextStep, step, typeWriter, playKeyboard]);

    useEffect(() => {
        const elementCounter = refCounter.current;
        const innerText = elementCounter && elementCounter.innerText;

        const currentNum = innerText ? parseInt(innerText) : null;

        if (elementCounter && innerText && currentNum) {
            setIsStartCounter(true);
        }
    }, [step]);

    return (
        <>
            <div className="reels-welcome-body">
                <div className="machine__banner">
                    <img
                        className="machine__banner-body"
                        src="/assets/images/style/header-banner-default.png"
                        alt="askstudio casino"
                        data-is-visible="true"
                    />
                </div>
                <div className="reels-welcome-body__wrap">
                    <h2
                        className="reels-welcome-body__greeting-text"
                        id="machine-greeting0"
                        ref={refTypeWriter}
                    >
                        {" "}
                    </h2>

                    <p id="machine-message1" />

                    <div className="reels-welcome-body__counter-text">
                        <p id="machine-message2" />
                        <p
                            className="reels-welcome-body__counter"
                            id="machine-message3"
                            ref={refCounter}
                        />
                        <p id="machine-message4" />
                    </div>

                    <p id="machine-message5" />
                    <p id="machine-message6" />
                </div>
                <div className="reels-welcome-body__footer">
                    <img
                        className="reels-welcome-body__footer-image"
                        src="/assets/images/style/roll-footer-lets-play.png"
                        alt="reels footer"
                    />
                </div>
            </div>
        </>
    );
};

export default WelcomeReels;

