import React, {useEffect, useMemo, useState} from "react";
import {machineAnswerKeys} from "../../../constants/slotMachineSettings";
import {answerKeysCheck} from "../../../utils/KeysCheck";
import {QuestionReelsProps} from "./QuestionReelsShape";
import "./QuestionReelsStyles.scss";
import useSound from 'use-sound';
import buttonSound from '../../../assets/sound/button.mp3';


const QuestionReels: React.FC<QuestionReelsProps> = (props) => {
    const {question, pressedKey, isKeyDown} = props;

    const [isLocalKeyDown, setIsLocalKeyDown] = useState(false);
    const [localPressedButton, setLocalPressedButton] = useState(0);

    const [questionKeyPlay] = useSound(buttonSound, {volume: 0.25});

    const keysMap = useMemo(
        () => Object.keys(question.answerMapping),
        [question.answerMapping]
    );

    useEffect(() => {
        if (
            !isLocalKeyDown &&
            isKeyDown &&
            answerKeysCheck(
                pressedKey,
                machineAnswerKeys,
                question.answerOptions.length - 1
            )
        ) {
            questionKeyPlay();
            const indexKey = machineAnswerKeys.indexOf(pressedKey) + 1;

            setLocalPressedButton(indexKey);
            setIsLocalKeyDown(true);
        }

    }, [isLocalKeyDown, isKeyDown, pressedKey, question, questionKeyPlay]);

    return (
        <div className="reels-question-body">
            <div className="machine__banner">
                <img
                    className="machine__banner-body"
                    src="/assets/images/style/header-banner-default.png"
                    alt="ask-studio casino"
                    data-is-visible="true"
                />
            </div>

            <div className="reels-question-body__label">
                <h2>{question.title}</h2>
            </div>

            <div className="reels-question-body__table">
                {question.answerOptions.map((answer, index) => {
                    const slotNumber = index + 1;

                    const keysMapping = keysMap.find(
                        (mapping) => mapping === answer
                    );

                    return (
                        <div
                            className="reels-question-body__table-column"
                            key={`quest-${index}`}
                        >
                            <p className="reels-question-body__slot-number">
                                {slotNumber}
                            </p>
                            <div
                                className="question"
                                data-is-key-down={
                                    slotNumber === localPressedButton
                                }
                            >
                                {keysMapping
                                    ? question.answerMapping[keysMapping]
                                    : answer}
                            </div>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

export default QuestionReels;
